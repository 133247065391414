import React from "react"
import { graphql } from "gatsby"
import { Grid, Box, Text } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import MdToReact from "../components/MdToReact"

export const query = graphql`
  query($slug: String!) {
    contentfulNewsArticle(slug: { eq: $slug }) {
      headline
      createdAt(formatString: "MMMM D, YYYY")
      body {
        json
      }
    }
  }
`

const NewsArticle = props => { 
  return (
    <Layout>
      <SEO title={props.data.contentfulNewsArticle.headline} />

      <Breadcrumbs parent={["News", "/news"]}/>

      <Grid variant="base" columns={[1, 1, 1, "1fr 2fr"]}>
        <Box>
          <Text as="h1" variant="display" sx={{ mr: [0, 4] }}>{props.data.contentfulNewsArticle.headline}</Text>
          <Text as="p" variant="label">{props.data.contentfulNewsArticle.createdAt}</Text>
        </Box>

        <MdToReact content={props.data.contentfulNewsArticle.body.json} />
      </Grid>
    </Layout>
  )
}

export default NewsArticle